<template>
    <div class="title-template-table  table-responsive">
        <table class="table table-hover table-head-fixed table-hover mb-3">
            <thead>
                <tr>
                    <th>Phân loại</th>
                    <th class='text-center'>Cấu hình</th>
                    <th class="text-right">Chức năng</th>
                </tr>
            </thead>
            <tbody v-if="!items || !items.length" >
                <tr>
                    <td colspan="3" class="text-center text-danger">Danh sách cấu hình trống</td>
                </tr>
            </tbody>
            <tr v-for="(item, index) in items" :key="index" class="tr-draggable">
                <td>
                    <span v-if='item.type && $params.client.typeOptions.find(o => o.value === item.type)'>{{$params.client.typeOptions.find(o => o.value === item.type).text}}</span>
                </td>
                <td class='text-center'>
                    <span v-if='item.fields && item.fields.length' class='small text-success' >Đã cấu hình</span>
                    <span v-else class='small text-danger' >Chưa cấu hình</span>
                </td>
                <td class="text-right">
            <g-button :loading="updateLoading" size="sm" variant="outline-info" class="ml-1 mb-1" icon="pencil" @click='update(index)'/>
            <g-button :loading="delLoading" size="sm" variant="outline-danger" class="ml-1 mb-1" icon="trash-fill" @click='del(index)' />
            </td>
            </tr>
        </table>
        <b-modal v-model="showForm" size='xl' :title="`${formTitle}`" no-close-on-backdrop no-enforce-focus>
            <div class='row'>
                <div class='col-4'>
                    <draggable id="first" data-source="juju" :list="indicators" class="list-group" draggable=".item" group="a" style="min-height: 500px;">
                        <div class="list-group-item item" v-for="element in indicators" :key="element.code" >
                            <b>{{element.code}}</b>. {{ element.name }} <b v-if="element.c">{{element.c}}</b> <span v-if="element.required" class="text-danger" >(*)</span>
                        </div>
                    </draggable>
                </div>
                <div class='col-8'>
                    <draggable id="first" data-source="juju" :list="formModel.fields" class="list-group" draggable=".item" group="a" style="min-height: 500px;">
                        <div class="list-group-item item row" v-for="element in formModel.fields" :key="element.code">
                            <div class='col-2 mt-2'><b :class="[element.type === 'indicator' ? 'ml-4' : '']" >{{element.code}}</b></div>
                            <div class='col-8'>
                                <b-form-input v-model="element.name" type="text" class="form-control" />
                            </div>
                            <div class='col-2'>
                                <b v-if="element.c">{{element.c}}</b> <span v-if="element.required" class="text-danger" >(*)</span>
                            </div>
                        </div>
                        <div slot="header" class="btn-group list-group-item" role="group" aria-label="Basic example">
                            <button class="btn btn-sm btn-success" @click="addGroup">
                                <i class='fa fa-plus'></i>
                                Thêm nhóm
                            </button>
                        </div>
                    </draggable>
                </div>
            </div>

            <template v-slot:modal-footer>
                <b-button variant="secondary" class="float-right" @click="showForm = false">Huỷ</b-button>
                <b-button :disabled="saveLoading" variant="primary" class="float-right" @click="save">
                    <b-spinner v-if="saveLoading" small></b-spinner>
                    Lưu lại
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';
    import crud from '@/mixins/CRUD';
    export default {
        mixins: [crud],
        components: {draggable},
        props: ['model', 'attribute', 'label', 'group'],
        data() {
            return {
                defaultFormModel: {
                    type: null,
                    fields: [],
                },
                items: this.model[this.attribute] ? this.model[this.attribute] : [],
                state: null,
                errorTxt: '',
                formErrors: {},
                indicators: [],
            }
        },
        watch: {
            model: {
                handler(val) {
                    this.state = null;
                    this.errorTxt = '';
                    if (val[this.attribute] && val[this.attribute] !== null) {
                        this.items = val[this.attribute];
                    } else {
                        this.items = null;
                    }
                }, deep: true
            },
            items(val) {
                this.state = null;
                this.errorTxt = '';
                this.model[this.attribute] = val;
            },
            errors(val) {
                let hasErr = false;
                if (val.length) {
                    val.forEach(v => {
                        if (v.param === this.attribute) {
                            this.state = false;
                            this.errorTxt = v.msg;
                            hasErr = true;
                            return;
                        }
                    });
                }
                if (!this.errorOnly) {
                    this.state = !hasErr;
                }
            }
        },
        methods: {
            load() {
                for (let option of this.$params.client.typeOptions) {
                    if (!this.group.includes(option.value)) {
                        continue;
                    }
                    let item = this.items.find(i => i.type === option.value);
                    if (!item && option.value) {
                        this.items.push({
                            type: option.value,
                            fields: []
                        });
                    }
                }
            },
            save: function (evt) {
                this.items = !this.items || !this.items.length ? [] : this.items;
                evt.preventDefault();
                this.items[this.formModel.id] = this.formModel;
                this.showForm = false;
                this.load();
                this.$bus.$emit("dashboardSave");
            },
            update: function (index) {
                this.formModel = {};
                this.formErrors = [];
                this.showForm = true;
                this.formModel = {...this.items[index]};
                this.formModel.id = `${index}`;
                this.formTitle = "Cập nhật";
                if (this.$params.client.typeOptions.find(o => o.value === this.formModel.type)) {
                    this.formTitle += ' ' + this.$params.client.typeOptions.find(o => o.value === this.formModel.type).text
                }

                this.indicators = [];
                for (let code in this.$params.indicator) {
                    if (this.$params.indicator[code].indicator !== undefined && this.$params.indicator[code].indicator === false) {
                        continue;
                    }
                    let field = this.formModel.fields.find(f => f.code === code);
                    if (!field) {
                        let indicator = {...this.$params.indicator[code]};
                        indicator.c = `${indicator.code}`;
                        indicator.code = code;
                        indicator.type = "indicator";
                        this.indicators.push(indicator);
                    } 
                }

            },
            del: function (index) {
                this.$swal({
                    title: "Bạn chắc chắn muốn xóa chứ?",
                    text: "Sẽ không thể khôi phục lại dữ liệu đã xóa!",
                    icon: "warning",
                    showCancelButton: true
                }).then((result) => {
                    if (result.value) {
                        this.items.splice(index, 1);
                        this.$bus.$emit("dashboardSave");
                    }
                });
            },
            addGroup: function () {
                this.formModel.fields.push({
                    type: "group",
                    code: 'group_' + (this.formModel.fields.length + 1),
                    name: "Nhóm ",
                });
            },
        },
        mounted() {
            this.items = this.model[this.attribute] ? this.model[this.attribute] : [];
            this.load();
        }
    }
</script>