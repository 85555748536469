<template>
    <div class="row">
        <g-loading v-if="firstLoading" />
        <div v-else class="col-12">
            <div class="card title">
                <div class="card-body title-body">
                    <h3 class="tile-title is-line mb-2">
                        <span class='text-black'>Cài đặt hiển thị giao diện Dashboard cho <b class="text-danger">Thầu chính, Ban quản lý dự án TW</b></span>
                    </h3>
                    <form-dashboard :model="formModel" :errors="formErrors" attribute="prime" :group="['prime', 'tw', 'usaid']" />
                </div>
            </div>

            <div class="card title">
                <div class="card-body title-body">
                    <h3 class="tile-title is-line mb-2">
                        <span class='text-black'>Cài đặt hiển thị giao diện Dashboard cho <b class="text-danger">thầu phụ</b></span>
                    </h3>
                    <form-dashboard-b :model="formModel" :errors="formErrors" attribute="sub" :group="['prime', 'tw']" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import crud from '@/mixins/CRUD';
    import FormDashboard from './FormDashboard';
    import FormDashboardB from './FormDashboardB';

    export default {
        mixins: [crud],
        components: {FormDashboard, FormDashboardB},
        data: function () {
            return {
                firstLoading: true,
                service: '/etc',
                defaultFormModel: {
                    config: []
                },
                form: {
                    key: 'dashboard',
                    type: 'JSON',
                    value: null,
                }
            };
        },
        methods: {
            save: function () {
                this.form.value = JSON.stringify(this.formModel);
                this.saveLoading = true;
                let method = 'post';
                let url = this.service;
                if (this.form.id) {
                    method = 'put';
                    url += `/update?id=${this.form.id}`;
                } else {
                    method = 'post';
                    url += '/create';
                }
                if (this.beforeSave) {
                    this.beforeSave();
                }
                return this.$service({url: url, method: method, data: this.form}).then(response => {
                    this.showForm = false;
                    if (this.load) {
                        this.load();
                    }
                    if (this.reloadBus) {
                        this.$bus.$emit(this.reloadBus);
                    }
                    if (response.data) {
                        this.$swal({
                            title: "Thành công",
                            text: 'Cập nhật thành công',
                            icon: 'success',
                            toast: true,
                            position: 'bottom-end',
                            timer: 5000,
                        });
                    } else {
                        this.$swal({
                            title: "Có lỗi",
                            text: 'Cập nhật thất bại',
                            icon: 'error',
                            toast: true,
                            position: 'bottom-end',
                            timer: 5000,
                        });
                    }
                    return response.data;
                }).catch(error => {
                    if (error.response.data.message) {
                        this.$swal({
                            text: error.response.data.message,
                            icon: 'error'
                        });
                        this.showForm = false;
                    } else {
                        this.$swal({
                            text: "Vui lòng kiểm tra lại thông tin",
                            icon: 'warning',
                            toast: true,
                            position: 'bottom-end',
                            timer: 3000,
                        });
                        this.formErrors = error.response.data;
                    }
                }).finally(() => {
                    this.saveLoading = false;
                });
            },
        },
        mounted: function () {
            this.$service.get(`${this.service}/get-by-key?key=${this.form.key}`).then(response => {
                if (response.data) {
                    this.form = response.data;
                    this.formModel = response.data.value ? JSON.parse(response.data.value) : Object.assign({}, this.defaultFormModel);
                } else {
                    this.formModel = Object.assign({}, this.defaultFormModel);
                }
                this.formErrors = {};
            }).finally(() => {
                this.firstLoading = false;
            });

            this.$bus.$on('dashboardSave', this.save);
        },
        beforeDestroy: function () {
            this.$bus.$off('dashboardSave');
        },
    }
</script>